import React from "react"

class Faq extends React.Component 
{
    constructor(props) {
        super(props);
        this.state = {
            answer: ''
        }
    }

    handleToggle = (i,e) => {
        e.preventDefault();
        let target = i
        if (this.state.answer === target) { 
            this.setState({
                answer: ''
            });
        } else {
            this.setState({
                answer: target
            })
        }
        //console.log(`Child: `, this.state.answer);
        //console.log(`Target: `, target);
    }

    render () {
        //console.log(`data: `, this.props.data);
        const entry = this.props.data
        return (
            <div>
                {entry.faqs.faq.map((item, key) => (
                    <div className="faq__item" key={key}>
                        <button onClick={(e) => this.handleToggle(key, e)}>
                            <div className="faq__border">
                                <div className="faq__block">
                                    <div className="faq__block--left">
                                        <h2 className="faq__question">{item.question}</h2>
                                    </div>
                                    <div className="faq__block--right">
                                        <span className="faq__question">{this.state.answer === key ? `-` : `+`}</span>
                                    </div>
                                </div>
                                <div className={this.state.answer === key ? `faq__answer faq__block` : `faq__answer faq__block faq__block--hidden`}>
                                    <p>{item.answer}</p>
                                </div>
                            </div>
                        </button>
                    </div>
                ))}
            </div>
        )
    }
}

export default (faqs) => (
    <Faq data={faqs} />
)